"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getters = {
    // USER GETTERS
    'user/self': (state) => state.user,
    // CHAT GETTERS
    'chat/contacts': (state) => state.contacts,
    'chat/channels': (state) => state.channels,
    'chat/open': (state) => state.openChannel,
    isInFullscreen() {
        if (window.matchMedia('(display-mode: fullscreen)').matches) {
            return true;
        }
        return false;
    },
};
exports.default = getters;
