import store from '@/store';
export default {
  install(Vue) {
    Vue.prototype.$http.get('/domain').then(({ data }) => {
      store.dispatch('setDomain', data);
      document.documentElement.style.setProperty('--primary-color', data.color);
      document.documentElement.style.setProperty('--primary-color-darken', data.darken);
      document.documentElement.style.setProperty('--primary-color--transparent', data.color + '1c');
      document.documentElement.style.setProperty(
        '--primary-color--transparent-2',
        data.color + '06'
      );
    });
  },
};
