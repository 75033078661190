var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"header",staticClass:"message-view__chat-header",attrs:{"data-app":""}},[_c('div',{staticClass:"message-view__back-button"},[_c('v-btn',{attrs:{"icon":"","color":"#ff7f00"},on:{"click":_vm.handleClickCloseChat}},[_c('i',{staticClass:"fa fa-chevron-left",attrs:{"aria-hidden":"true"}})])],1),_c('div',{staticClass:"message-view__chat-avatar",class:{
      'message-view__chat-avatar--online': _vm.isUserOnline(_vm.$store.getters['chat/open']) || _vm.isAI,
      'message-view__chat-avatar--ai': _vm.isAI,
    }},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({style:({
            backgroundColor: _vm.$store.getters['chat/open'].users.length == 2 ? 'white' : '',
          }),attrs:{"size":"40px"}},'v-avatar',attrs,false),on),[(_vm.$store.getters['chat/open'].users.length > 2)?_c('i',{staticClass:"fa-duotone fa-people-group"}):_c('v-img',{attrs:{"src":_vm.channelAvatar(_vm.$store.getters['chat/open'])}})],1)]}}])},[(_vm.$store.getters['chat/open'].users.length > 2)?_c('v-list',{staticStyle:{"max-height":"300px","overflow-y":"auto"}},_vm._l((_vm.sortedUsersAlphabetically),function(user){return _c('v-list-item',{key:'channel-user-list-' + user.id},[_c('v-list-item-avatar',{class:{
              'message-view__chat-avatar--online': _vm.isUserOnline(_vm.$store.getters['chat/open'])[
                user.user.id
              ],
            }},[_c('v-avatar',{attrs:{"size":"40px"}},[_c('v-img',{attrs:{"src":user.user.picture}})],1)],1),_c('v-list-item-title',{staticClass:"message-view__channel-user-list"},[_c('router-link',{staticStyle:{"color":"unset"},attrs:{"to":user.user.slug}},[_vm._v(_vm._s(user.user.name))]),_c('br'),_c('small',{staticClass:"text-white"},[_vm._v(_vm._s(user.user.role))])],1)],1)}),1):_vm._e()],1)],1),_c('div',{staticClass:"message-view__chat-name"},[_vm._v(" "+_vm._s(_vm.channelName(_vm.$store.getters['chat/open']))+" "),(
        (_vm.isUserOnline(_vm.$store.getters['chat/open']) || _vm.isAI) &&
        _vm.$store.getters['chat/open'].users.length == 2
      )?_c('v-list-item-subtitle',{staticClass:"message-view__typing-or-online"},[_vm._v(" "+_vm._s(_vm.$store.state.isTyping ? 'is typing...' : 'online')+" ")]):(_vm.$store.getters['chat/open'].users.length == 2)?_c('v-list-item-subtitle',{staticClass:"message-view__typing-or-online"},[(_vm.isAI)?[_vm._v("online")]:[_vm._v(" offline ")]],2):_vm._e(),(_vm.$store.getters['chat/open'].users.length > 2)?_c('v-list-item-subtitle',{staticClass:"message-view__typing-or-online"},[_vm._v(" "+_vm._s(_vm.$store.state.userTyping.id ? _vm.$store.state.userTyping.name + ' is typing...' : _vm.$store.getters['chat/open'].users.length + ' utilizadores')+" ")]):_vm._e()],1),_c('div',{staticClass:"message-view__chat-options"},[_c('ChatOptionsComponent',{on:{"onArchive":function($event){return _vm.$emit('onArchive')},"onDelete":function($event){return _vm.$emit('onDelete')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }