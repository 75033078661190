<template>
  <div class="message-view__chat-header" ref="header" data-app>
    <div class="message-view__back-button">
      <v-btn icon color="#ff7f00" @click="handleClickCloseChat">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </v-btn>
    </div>
    <div
      class="message-view__chat-avatar"
      :class="{
        'message-view__chat-avatar--online': isUserOnline($store.getters['chat/open']) || isAI,
        'message-view__chat-avatar--ai': isAI,
      }"
    >
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            size="40px"
            :style="{
              backgroundColor: $store.getters['chat/open'].users.length == 2 ? 'white' : '',
            }"
            v-bind="attrs"
            v-on="on"
          >
            <i
              class="fa-duotone fa-people-group"
              v-if="$store.getters['chat/open'].users.length > 2"
            ></i>
            <v-img v-else :src="channelAvatar($store.getters['chat/open'])"></v-img>
          </v-avatar>
        </template>
        <v-list
          v-if="$store.getters['chat/open'].users.length > 2"
          style="max-height: 300px; overflow-y: auto"
        >
          <v-list-item
            v-for="user in sortedUsersAlphabetically"
            :key="'channel-user-list-' + user.id"
          >
            <v-list-item-avatar
              :class="{
                'message-view__chat-avatar--online': isUserOnline($store.getters['chat/open'])[
                  user.user.id
                ],
              }"
            >
              <v-avatar size="40px">
                <v-img :src="user.user.picture"></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title class="message-view__channel-user-list">
              <router-link :to="user.user.slug" style="color: unset">{{
                user.user.name
              }}</router-link>
              <br />
              <small class="text-white">{{ user.user.role }}</small>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="message-view__chat-name">
      {{ channelName($store.getters['chat/open']) }}

      <v-list-item-subtitle
        v-if="
          (isUserOnline($store.getters['chat/open']) || isAI) &&
          $store.getters['chat/open'].users.length == 2
        "
        class="message-view__typing-or-online"
      >
        {{ $store.state.isTyping ? 'is typing...' : 'online' }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="message-view__typing-or-online"
        v-else-if="$store.getters['chat/open'].users.length == 2"
      >
        <template v-if="isAI">online</template>
        <template v-else> offline </template>
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="message-view__typing-or-online"
        v-if="$store.getters['chat/open'].users.length > 2"
      >
        {{
          $store.state.userTyping.id
            ? $store.state.userTyping.name + ' is typing...'
            : $store.getters['chat/open'].users.length + ' utilizadores'
        }}
      </v-list-item-subtitle>
    </div>
    <div class="message-view__chat-options">
      <ChatOptionsComponent @onArchive="$emit('onArchive')" @onDelete="$emit('onDelete')" />
    </div>
  </div>
</template>

<!-- lang="ts" -->
<script>
import Vue from 'vue';

import ChatOptionsComponent from './ChatOptionsComponent.vue';

import channelMixins from '@/mixins/channelMixins';

// import { User } from '@/store/store';

export default Vue.extend({
  mixins: [channelMixins],
  components: {
    ChatOptionsComponent,
  },
  watch: {
    '$store.state.openChannel': function () {
      this.$store.state.isTyping = false;
      this.userTyping = {}; // as User;
    },
  },
  mounted() {
    this.initialInnerHeight = window.screen.availHeight;
  },
  computed: {
    isAI() {
      return Boolean(this.$store.getters['chat/open'].users.find((u) => u.user_id === 10265));
    },
    sortedUsersAlphabetically() {
      return this.$store.getters['chat/open'].users.sort((a, b) => {
        if (a.user.name < b.user.name) {
          return -1;
        }
        if (a.user.name > b.user.name) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    handleClickCloseChat() {
      this.$router
        .replace({
          path: '/',
        })
        .catch(() => {
          1;
        });
      this.$store.commit('chat/close');
    },
    async timeout(timer) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, timer);
      });
    },
    async fixHeader(bool) {
      //: boolean
      const header = this.$refs.header;
      await this.timeout(100);
      if (bool) {
        header.style.position = 'sticky';
        header.style.top = '0px';
        header.style.transform = `translateY(${window.scrollY}px)`;
      } else {
        header.style.position = 'inherit';
        header.style.transform = `translateY(0px)`;
      }
    },
  },
  data: () => ({
    showUsersList: false,
    userTyping: {}, // as User,
    initialInnerHeight: 0,
  }),
});
</script>

<style lang="scss">
.message-view {
  &__chat-header {
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: 50px 40px 1fr 50px;
    gap: 16px;
    align-items: center;
    justify-content: center;

    z-index: 1;

    @media (prefers-color-scheme: light) {
      background-color: var(--light-primary-color);
      border-bottom: 1px solid var(--light-secondary-color);
    }

    @media (prefers-color-scheme: dark) {
      background-color: var(--dark-primary-color);
      border-bottom: 1px solid var(--dark-secondary-color);
    }
  }

  &__back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    & i {
      cursor: pointer;
    }

    @media (prefers-color-scheme: light) {
      color: var(--dark-secondary-color);
    }

    @media (prefers-color-scheme: dark) {
      color: var(--light-secondary-color);
    }
  }

  &__chat-avatar {
    &--online .v-avatar {
      border: 2px solid var(--success-color);
      background-color: var(--success-color-transparent) !important;
    }
    &--ai .v-avatar {
      background-color: rgb(216, 244, 222) !important;
    }
  }

  &__chat-name {
    font-size: 1rem;

    & .message-view__typing-or-online {
      font-size: 0.8rem;
      color: #888;
    }
  }

  &__chat-options {
    display: flex;
    align-items: center;
    justify-content: center;

    & i {
      cursor: pointer;
    }
  }

  &__channel-user-list {
    font-size: 0.8rem;

    margin-left: 10px;

    @media (prefers-color-scheme: light) {
      color: var(--dark-secondary-color);
    }

    @media (prefers-color-scheme: dark) {
      color: var(--light-secondary-color);
    }
  }
}
</style>
