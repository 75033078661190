<template>
  <div class="widget">
    <div class="widget__content" ref="widgetContent">
      <iframe src="http://localhost:8080/nuno-pereira" frameborder="0"></iframe>
    </div>
    <button class="widget__button" @click="handleWidgetButtonClick">
      <i class="fa-brands fa-whatsapp"></i>
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    handleWidgetButtonClick() {
      this.$refs.widgetContent.classList.toggle('widget__content--active');
    },
  },
};
</script>

<style lang="scss">
.widget {
  &__content {
    position: fixed;
    bottom: 6rem;
    right: 2rem;
    width: 0;
    height: 0;
    border-radius: 12px;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
    border: none;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: inherit;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    &--active {
      width: 400px;
      height: 500px;
      transform: scale(1);
    }
  }
  &__button {
    width: 60px;
    height: 60px;
    background-color: #ff7f00;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    font-size: 40px;
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}
</style>
