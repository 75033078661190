var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scrollableDiv",staticClass:"message-view",style:({
    maxHeight: "calc(100dvh - 110px)",
  }),on:{"scroll":_vm.handleScroll}},[_c('CoolLightBox',{attrs:{"items":_vm.images,"index":_vm.imagesIndex,"effect":'fade'},on:{"close":function($event){_vm.imagesIndex = null}}}),_vm._l((_vm.groupedMessages),function(value,date){return _c('div',{key:("date-" + date),staticClass:"message-view__date"},[_c('div',{staticClass:"message-view__date-wrapper"},[_c('div',{staticClass:"message-view__date-divider"}),_c('div',{staticClass:"message-view__date-text"},[_c('v-chip',{staticClass:"message-view__date-text__chip"},[_vm._v(" "+_vm._s(date)+" ")])],1),_c('div',{staticClass:"message-view__date-divider"})]),_c('div',{staticClass:"message-view__chat-messages"},_vm._l((value),function(message,messageIndex){return _c('div',{key:"message-" + messageIndex + '-' + _vm.$store.getters['chat/open'].id,staticClass:"message-view__messages",attrs:{"data-message-id":message.id}},[_c('div',{staticClass:"message-view__direction",class:{
            'message-view__direction--sender':
              message.sender_id == _vm.$store.getters['user/self'].id,
          }},[_c('v-avatar',{staticClass:"message-view__avatar",class:{
              'message-view__avatar--sender': message.sender_id == _vm.$store.getters['user/self'].id,
            },staticStyle:{"background-color":"white"},attrs:{"size":"30px"}},[(message.sender)?_c('v-img',{attrs:{"src":message.sender.picture}}):_vm._e()],1),_c('div',{directives:[{name:"hammer",rawName:"v-hammer:press",value:(_vm.onPress.bind(null, message.id)),expression:"onPress.bind(null, message.id)",arg:"press"}],staticClass:"message-view__message",class:{
              'message-view__sender': message.sender_id == _vm.$store.getters['user/self'].id,
              'message-view__message--long-pressing': _vm.showOptions[message.id],
            },attrs:{"id":("scrollToIfReplied-" + (message.id)),"data-message-id":message.id}},[(message.sender_id == _vm.$store.getters['user/self'].id)?_c('div',{staticClass:"message-view__message-options message-view__message-options--delete",class:{
                'message-view__message-options--sender':
                  message.sender_id == _vm.$store.getters['user/self'].id,
              }},[_c('span',{attrs:{"data-tooltip":"Apagar Mensagem"}},[_c('v-btn',{attrs:{"fab":"","x-small":"","elevation":"0","color":message.sender_id == _vm.$store.getters['user/self'].id
                      ? 'white'
                      : _vm.$store.state.domain.color},on:{"click":function($event){return _vm.handleDeleteMessage(message)}}},[_c('i',{staticClass:"fal fa-trash",style:({
                      color: _vm.$store.state.domain.color,
                    }),attrs:{"aria-hidden":"true"}})])],1)]):_vm._e(),(message.sender_id != _vm.$store.getters['user/self'].id)?_c('div',{staticClass:"message-view__message-options message-view__message-options--reply"},[_c('span',{attrs:{"data-tooltip":"Responder"}},[_c('v-btn',{attrs:{"fab":"","x-small":"","elevation":"0","color":message.sender_id == _vm.$store.getters['user/self'].id
                      ? 'white'
                      : _vm.$store.state.domain.color},on:{"click":function($event){return _vm.replyMessage(message)}}},[_c('i',{staticClass:"fa-light fa-reply"})])],1)]):_vm._e(),(
                _vm.$store.getters['chat/open'].users.length > 2 &&
                message.sender_id != _vm.$store.getters['user/self'].id
              )?_c('router-link',{staticClass:"message-view__sender--name",attrs:{"to":("/" + (message.sender.slug))}},[_c('p',{style:({
                  color: ((_vm.savedUsersColors[message.sender_id]) + " !important"),
                })},[_vm._v(" "+_vm._s(message.sender.name)+" ")])]):(_vm.$store.getters['chat/open'].users.length > 2)?_c('p',[_vm._v(" "+_vm._s(message.sender.name)+" ")]):_vm._e(),(message.reply)?_c('div',{staticClass:"message-view__reply-message",on:{"click":function($event){return _vm.scrollToMessage(message)}}},[(message.reply && message.reply.sender)?_c('p',{style:({
                  color: ((_vm.savedUsersColors[message.reply.sender_id]) + " !important"),
                })},[_vm._v(" "+_vm._s(message.reply.sender.name)+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(message.reply.message)}})]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(message.message)},on:{"click":function($event){return _vm.handleClickMessage(message)}}}),_c('small',{key:_vm.$store.state.hotReloadReads,staticClass:"message-view__timestamp",class:{
                'message-view__timestamp--sender':
                  message.sender_id == _vm.$store.getters['user/self'].id,
              }},[_c('p',[_vm._v(" "+_vm._s(_vm.calcPostedAt(message.createdAt))+" "),_c('span',[(message.sender_id == _vm.$store.getters['user/self'].id)?_c('span',[(_vm.readAt(message))?_c('i',{staticClass:"fa-solid fa-check-double message-view__read"}):_c('i',{staticClass:"fa-solid fa-check"})]):_vm._e()])])])],1)],1),(_vm.isLastReadMessage(message))?_c('div',{staticClass:"message-view__new-messages"},[_c('div',{staticClass:"message-view__new-messages--divider"}),_c('div',{staticClass:"message-view__new-messages--label"},[_vm._v("Novas Mensagens")]),_c('div',{staticClass:"message-view__new-messages--divider"})]):_vm._e()])}),0)])}),(_vm.$store.state.replyMessage)?[_c('br'),_c('br'),_c('br')]:_vm._e(),(_vm.$store.state.files.length > 0)?[_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')]:_vm._e(),(_vm.showScrollDownButton)?_c('v-btn',{staticStyle:{"bottom":"80px"},attrs:{"fixed":"","right":"","fab":"","small":"","color":_vm.$store.state.domain.color},on:{"click":function($event){return _vm.scrollToBottom(true)}}},[_c('i',{staticClass:"fa fa-arrow-down text-white",staticStyle:{"font-size":"16px","color":"white"},attrs:{"aria-hidden":"true"}})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }