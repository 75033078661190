<template>
  <div>
    <transition name="slither">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'NotAuthenticatedLayout',
};
</script>
