"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterChannels = void 0;
const filterChannels = (filter, channelsToFilter, self) => {
    let channels = [];
    if (filter === 'ALL') {
        channels = channelsToFilter
            .filter((c) => {
            const archivedUsers = c.users.filter((u) => {
                return u.archived;
            });
            if (archivedUsers.length > 0) {
                const archivedByMe = archivedUsers.find((u) => u.user.id == self.id);
                return !archivedByMe;
            }
            else {
                return archivedUsers.length == 0 && c.users.length <= 2;
            }
        })
            .sort((a, b) => {
            if (a.lastMessage && b.lastMessage) {
                const dateA = new Date(a.lastMessage.createdAt);
                const dateB = new Date(b.lastMessage.createdAt);
                return dateB.getTime() - dateA.getTime();
            }
            else if (a.lastMessage) {
                return -1;
            }
            else if (b.lastMessage) {
                return 1;
            }
            else {
                return 0;
            }
        });
    }
    else if (filter === 'GROUPS') {
        channels = channelsToFilter
            .filter((c) => {
            return c.users.length > 2;
        })
            .filter((c) => {
            const archivedUsers = c.users.filter((u) => {
                return u.archived;
            });
            const archivedByMe = archivedUsers.find((u) => u.user.id == self.id);
            return !archivedByMe;
        })
            .sort((a, b) => {
            if (a.lastMessage && b.lastMessage) {
                const dateA = new Date(a.lastMessage.createdAt);
                const dateB = new Date(b.lastMessage.createdAt);
                return dateB.getTime() - dateA.getTime();
            }
            else if (a.lastMessage) {
                return -1;
            }
            else if (b.lastMessage) {
                return 1;
            }
            else {
                return 0;
            }
        });
    }
    else if (filter === 'ARCHIVEDS') {
        channels = channelsToFilter
            .filter((c) => {
            const archivedUsers = c.users.filter((u) => {
                return u.archived;
            });
            const archivedByMe = archivedUsers.find((u) => u.user.id == self.id);
            return archivedByMe;
        })
            .sort((a, b) => {
            if (a.lastMessage && b.lastMessage) {
                const dateA = new Date(a.lastMessage.createdAt);
                const dateB = new Date(b.lastMessage.createdAt);
                return dateB.getTime() - dateA.getTime();
            }
            else if (a.lastMessage) {
                return -1;
            }
            else if (b.lastMessage) {
                return 1;
            }
            else {
                return 0;
            }
        });
    }
    return channels;
};
exports.filterChannels = filterChannels;
