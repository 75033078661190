/**
 * Here is defined all layouts of the application.
 */

import Vue from 'vue';

import NotAuthenticatedLayout from './NotAuthenticatedLayout';
Vue.component('not-authenticated-layout', NotAuthenticatedLayout);

import AuthenticatedLayout from './AuthenticatedLayout';
Vue.component('authenticated-layout', AuthenticatedLayout);
