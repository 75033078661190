"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const state = {
    contacts: [],
    channels: [],
    openChannel: null,
    user: {
        id: -1,
        mls_id: -1,
        name: '',
        first_name: '',
        last_name: '',
        slug: '',
        picture: '',
        agency: '',
    },
    updateLastMessageCounter: 0,
    onlineUserSocket: [],
    userTyping: {},
    isTyping: false,
    isDarkMode: false,
    replyMessage: null,
    files: [],
    hotReloadReads: 0,
    lazyLoading: false,
    chatFilter: 'ALL',
    hotReloadMuted: 0,
    domain: {
        color: '#ff7f00',
        image_dark: '/assets/images/logo.svg',
        image_light: '/assets/images/logo_white.svg',
        noChatOpen: '/assets/images/noChatOpenImage.svg',
    },
};
exports.default = state;
