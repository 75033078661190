<template>
  <div class="message-view__pasting-message-wrapper">
    <div class="message-view__pasting-message-wrapper__border">
      <div class="message-view__pasting-message">
        <div
          class="message-view__pasting-file"
          v-for="(file, fileIndex) in $store.state.files"
          :key="file.name + fileIndex"
        >
          <div class="message-view__pasting-file__content">
            {{ file.name }}
            <img v-if="file.type.startsWith('image/')" :src="file.localUrl" />
          </div>
        </div>
      </div>
      <div class="message-view__file-and-times">
        <v-btn square text @click="resetPasteFile">
          <i class="fa fa-times" aria-hidden="true"></i>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    resetPasteFile() {
      this.$store.commit('chat/resetFiles');
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.message-view {
  &__pasting-message-wrapper {
    // position: absolute;
    z-index: 1000;
    bottom: 50px;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 16px;
    margin-top: -228px;
    width: 100%;
    height: auto;
    max-height: 280px;

    &__border {
      max-height: 260px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px;
      border-radius: 4px;

      border-left: 4px solid var(--primary-color);

      @media (prefers-color-scheme: dark) {
        background-color: #3a3a3a;
      }

      @media (prefers-color-scheme: light) {
        background-color: #f2f2f2;
      }
    }

    @media (prefers-color-scheme: dark) {
      background-color: #222222;
    }

    @media (prefers-color-scheme: light) {
      background-color: #ffffff;
    }
  }

  &__file-and-times {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  &__pasting-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-right: 10px;
  }

  &__pasting-file {
    display: flex;
    flex-direction: row;

    & img {
      max-height: 200px;
      min-height: 200px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      font-size: 14px;
    }
  }
}
</style>
