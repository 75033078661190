/**
 * Here is imported all views to be used at the routes.js file.
 */

import ChatView from './Chat/ChatView';

const views = {
  ChatView,
};

export default views;
