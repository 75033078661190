"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mutations = {
    'chat/close': (state) => {
        state.openChannel = null;
    },
    'user/SET_SOCKET_ID': (state, socketId) => {
        state.user.socketId = socketId;
    },
    'chat/setReplyMessage': (state, message) => {
        state.replyMessage = message;
    },
    'chat/setFile': (state, file) => {
        // generate local url to render image
        if (state.files.length > 0)
            state.files = [];
        file.localUrl = URL.createObjectURL(file);
        state.files.push(file);
    },
    'chat/resetFiles': (state) => {
        state.files = [];
    },
};
exports.default = mutations;
