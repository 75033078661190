<template>
  <div class="message-view__replying-message-wrapper">
    <div class="message-view__replying-message-wrapper__border">
      <div class="message-view__replying-message">
        <span>{{ $store.state.replyMessage.sender.name }}</span>
        <span v-html="addEllipsis(messageFormat)"></span>
      </div>
      <div class="message-view__file-and-times">
        <span
          v-if="isFile"
          class="message-view__replying-message__is-image"
          v-html="$store.state.replyMessage.message"
        ></span>
        <v-btn square text @click="resetReplyMessage">
          <i class="fa fa-times" aria-hidden="true"></i>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    resetReplyMessage() {
      this.$store.commit('chat/setReplyMessage', null);
    },
    addEllipsis(message) {
      this.windowWidth = window.innerWidth;

      let baseMath = 11;

      if (this.windowWidth <= 768) {
        baseMath = 18;
      } else {
        baseMath = 11;
      }

      const maxCharacters = Math.floor(this.windowWidth / baseMath); // Ajuste esse valor de acordo com suas necessidades

      if (message?.length > maxCharacters) {
        return message.slice(0, maxCharacters) + '...';
      }
      return message;
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.addEllipsis();
    window.addEventListener('resize', this.addEllipsis);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.addEllipsis);
  },
  computed: {
    messageFormat() {
      if (this.$store.state.replyMessage.message.includes('maischat.fra1')) {
        return '<i class="fal fa-files"></i>  Media';
      } else {
        return this.$store.state.replyMessage.message;
      }
    },
    isFile() {
      return (
        this.$store.state.replyMessage.message.includes('maischat.fra1') &&
        (this.$store.state.replyMessage.message.includes('png') ||
          this.$store.state.replyMessage.message.includes('jpg') ||
          this.$store.state.replyMessage.message.includes('jpeg') ||
          this.$store.state.replyMessage.message.includes('gif') ||
          this.$store.state.replyMessage.message.includes('webp'))
      );
    },
  },
};
</script>

<style lang="scss">
.message-view {
  &__replying-message-wrapper {
    // position: absolute;
    z-index: 1000;
    bottom: 50px;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 16px;
    margin-top: -48px;
    width: 100%;
    height: 100px;

    &__border {
      max-height: 80px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px;
      border-radius: 4px;

      border-left: 4px solid var(--primary-color);

      @media (prefers-color-scheme: dark) {
        background-color: #3a3a3a;
      }

      @media (prefers-color-scheme: light) {
        background-color: #f2f2f2;
      }
    }

    @media (prefers-color-scheme: dark) {
      background-color: #222222;
    }

    @media (prefers-color-scheme: light) {
      background-color: #ffffff;
    }
  }

  &__file-and-times {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  &__replying-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-right: 10px;

    &__is-image img {
      height: 100%;
      max-height: 60px;
      width: auto !important;
      margin-right: 16px;
    }
  }
}
</style>
