<template>
  <div class="contacts-filters">
    <v-btn
      v-for="filter in filters"
      :key="filter.filter"
      class="contacts-filters__filter"
      :class="{
        'contacts-filters__filter--active': $store.state.chatFilter == filter.filter,
      }"
      @click="$store.state.chatFilter = filter.filter"
      fab
      icon
      :data-tooltip="filter.label"
      :data-tooltip-conf="tooltipDirection"
    >
      <i :class="`fa-light ${filter.icon}`"></i>

      <div class="contacts-filters__unread" v-if="unreadMessages(filter) > 0">
        {{ unreadMessages(filter) }}
      </div>
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue';

import { filterChannels } from './_Contacts';

export default Vue.extend({
  props: {
    windowWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tooltipDirection() {
      return this.windowWidth > 768 ? 'right' : 'top';
    },
  },
  methods: {
    unreadMessages(filter) {
      let unreads = 0;
      const channels = filterChannels(
        filter.filter,
        this.$store.state.channels,
        this.$store.state.user
      );
      unreads = channels
        .map((c) => c.unreads ?? 0)
        .reduce((a, b) => {
          return a + b;
        }, 0);
      return unreads;
    },
  },
  data() {
    return {
      filters: [
        {
          filter: 'ALL',
          label: 'Conversas',
          icon: 'fa-comment',
        },
        {
          filter: 'GROUPS',
          label: 'Grupos',
          icon: 'fa-duotone fa-people-group',
        },
        {
          filter: 'ARCHIVEDS',
          label: 'Arquivados',
          icon: 'fa-box-archive',
        },
      ],
    };
  },
});
</script>

<style lang="scss">
.contacts-filters {
  width: 100%;
  height: 100%;

  @media (prefers-color-scheme: dark) {
    background-color: var(--dark-primary-color);
    border-right: 1px solid var(--dark-secondary-color);
    border-top: 1px solid var(--dark-secondary-color);
  }

  @media (prefers-color-scheme: light) {
    background-color: var(--light-primary-color);
    border-right: 1px solid var(--light-secondary-color);
    border-top: 1px solid var(--light-secondary-color);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &__filter {
    transition: all 0.5s ease-in-out;
    margin-top: 16px;
    border: 1px solid transparent;

    & i {
      font-size: 24px;
    }

    &:hover {
      @media (prefers-color-scheme: light) {
        background-color: var(--light-secondary-color);
      }
      @media (prefers-color-scheme: dark) {
        background-color: var(--dark-secondary-color);
      }
      transition: all 0.5s ease-in-out;
    }

    &--active {
      border: 1px solid var(--primary-color);
      @media (prefers-color-scheme: light) {
        background-color: var(--light-secondary-color);
      }
      @media (prefers-color-scheme: dark) {
        background-color: var(--dark-secondary-color);
      }

      & i {
        color: var(--primary-color);
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-around;

    &__filter {
      margin-top: 0;

      transform: scale(0.7);
    }
  }

  &__unread {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: inline-block;
    margin-left: 8px;
    color: white;

    font-size: 11px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 0;
    bottom: -16px;
  }
}
</style>
